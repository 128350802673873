import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import api from '../vue_shared/api.js'

export const REQUEST_VISIBILITY = {
  staff_visible: 'Staff',
  embargoed: 'Embargoed',
  published: 'Published',
  department_visible: 'Department',
  department_published: 'Published - department only',
  restricted: 'Restricted'
} as const

export interface RequestVisibility {
  value: keyof typeof REQUEST_VISIBILITY
  name: (typeof REQUEST_VISIBILITY)[keyof typeof REQUEST_VISIBILITY]
}

export type RequestVisibilityValue = keyof typeof REQUEST_VISIBILITY

export type RequestVisibilityLabel =
  (typeof REQUEST_VISIBILITY)[keyof typeof REQUEST_VISIBILITY]

export interface AccountState {
  prizmdoc_proxy_url: string // eslint-disable-line camelcase
  accept_terms: boolean // eslint-disable-line camelcase
  all_requests_page_subheader: string // eslint-disable-line camelcase
  anticipated_fulfillment_date: boolean // eslint-disable-line camelcase
  background_image_file_name: string | null // eslint-disable-line camelcase
  bucket_limit: number // eslint-disable-line camelcase
  active_doc_size_in_bytes: number // eslint-disable-line camelcase
  custom_fields_feature_flag: boolean // eslint-disable-line camelcase
  cybersource_activated: boolean // eslint-disable-line camelcase
  departments: { id: number; name: string }[]
  document_review: boolean // eslint-disable-line camelcase
  embargo_hours: number // eslint-disable-line camelcase
  embargoing_available: boolean // eslint-disable-line camelcase
  faq_header: string // eslint-disable-line camelcase
  front_page_header: string // eslint-disable-line camelcase
  front_page_text: string // eslint-disable-line camelcase
  id: number
  legacy_request_show_enabled: boolean // eslint-disable-line camelcase
  link_text: null // eslint-disable-line camelcase
  link_url: null // eslint-disable-line camelcase
  logo_file_name: null // eslint-disable-line camelcase
  name: string
  payments: boolean
  payment_processor_activated: boolean // eslint-disable-line camelcase
  pii_scan_feature_flag: boolean // eslint-disable-line camelcase
  redaction: boolean
  request_page_subheader: string // eslint-disable-line camelcase
  require_department: boolean // eslint-disable-line camelcase
  required_fields: boolean // eslint-disable-line camelcase
  restricted_visibilities_feature_flag: boolean // eslint-disable-line camelcase
  retention: boolean
  show_cost_publicly: boolean // eslint-disable-line camelcase
  show_dept_select: boolean // eslint-disable-line camelcase
  show_due_date: boolean // eslint-disable-line camelcase
  show_requester: boolean // eslint-disable-line camelcase
  sidebar_request_text: string // eslint-disable-line camelcase
  state: string
  stripe_activated: boolean // eslint-disable-line camelcase
  subdomain: string
  subscribe: boolean
  terms_and_conditions: string // eslint-disable-line camelcase
  uploaders_feature_flag: boolean // eslint-disable-line camelcase
  visibility_default: RequestVisibilityValue // eslint-disable-line camelcase
  notify_requesters_of_office_closures: boolean // eslint-disable-line camelcase
  civicplus_pay_activated: boolean // eslint-disable-line camelcase
  custom_field2_feature_flag: boolean // eslint-disable-line camelcase
  invoicing_feature_flag: boolean // eslint-disable-line camelcase
}

export interface UserState {
  email: string
  email_or_name: string // eslint-disable-line camelcase
  id: number
}

export interface RuleState {
  actions: string[]
  subject: string[]
}

export interface State {
  account: AccountState | null
  currentUser: UserState | null
  previewUser: string | null
  previewUserId: string | null
  rules: RuleState[]
}

export function accountRequestVisibilities(
  account: AccountState
): RequestVisibility[] {
  return Object.entries(REQUEST_VISIBILITY)
    .map(
      ([value, name]: [
        RequestVisibilityValue,
        RequestVisibilityLabel
      ]): RequestVisibility => ({
        value,
        name
      })
    )
    .filter(({ value }: { value: RequestVisibilityValue }) => {
      if (value === 'department_published') {
        return account.restricted_visibilities_feature_flag
      } else if (value === 'embargoed') {
        return account.embargoing_available
      }
      return true
    })
}

const globalModule: { state: State; [key: string]: any } = {
  state: {
    account: null,
    currentUser: null,
    previewUser: null,
    previewUserId: null,
    rules: []
  }, // data
  getters, // computed properties
  actions, // methods to retrieve data and call mutations
  mutations, // setting and updating state
  api // axios endpoints
}

export { globalModule }
